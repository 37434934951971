<template>
	<div class="container">
		<template v-if="step == 1">
			<div ref="qrCodeDiv" style="position: fixed;top: -9999px;left: -9999px;"></div>
			<LoginWrap v-show="showLogin" v-on:close-login="closeLogin()" />
			<div class="for-img" @click.stop="nav3D()">
				<div v-if="goods.isOpen == '是' && goods.consignmentType==2  && goods.period!=8" class="goods-img-wrap"
					:style="'background-image: url('+goods.pic+');'">
				</div>
				<img v-else-if="goods.isOpen == '是' && goods.consignmentType==2 && goods.period==8"
					src="https://ipfs.allnfts.cn/ipfs/QmY95PAi4TuXWeh2vdt3XcpSrn4M8LQDeeixULxLsmg6Fa"
					class="good-img" />
				<img v-else-if="goods.isOpen == '是' && goods.consignmentType==1 " :src="goods.pic" class="good-img" />
				<img v-if="goods.isOpen == '否'" src="../assets/manghe.png" class="good-img2" />
				<img src="../assets/3d.png" class="icon-3d" v-if="goods.iconType == 3">
				<img src="../assets/fd.png" class="icon-3d" v-if="goods.iconType == 1">
				<img v-if="tkData.id" src="../assets/icon-shop.png" class="icon-3d" @click="navTk()">
				<img src="../assets/goods/play2.png" class="icon-play" alt="" v-if="goods.iconType == 2">
				<video id="myVideo" v-show="videoModal" :src="goods.link3D" class="video" controls width="100%"
					height="100%"></video>
			</div>

			<div class="flex-row align-center good-name-sec">
				<img src="../assets/good_name_bg.png" class="good-name-bg" />
				<div class="flex-row align-center good-name-con space-between">
					<div class="good-name">{{goods.isOpen == '是' ? goods.name : '盲盒'}}</div>
					<!-- <div class="good-status-sec">
						<img src="../assets/good_status_bg.png" class="good-status-bg" />
						<div class="flex-column align-center good-status-con">
							<img src="../assets/sale_icon.png" class="sale-icon" />
							<div class="good-status">售卖中</div>
						</div>
					</div> -->
					<div class="flex-column zc-wrap">
						<div class="num-wrap2 flex-shrink flex-row align-center" v-if="goods.mergeRecordCount>0">
							<div class="num-wrap-ti">发行量</div>
							<div class="num-wrap-con">{{goods.mergeRecordCount}}件</div>
						</div>
						<div class="num-wrap2 flex-shrink flex-row align-center" v-if="goods.mergeRecordCount>0">
							<div class="points-wrap-ti">流通量</div>
							<div class="points-wrap-con">{{goods.quantity}}件</div>
						</div>
					</div>
				</div>
			</div>

			<div class="good-detail-1">
				<div class="label-item flex-row align-center flex-wrap">
					<!-- <div class="num-wrap flex-shrink flex-row align-center" v-if="goods.mergeRecordCount>0">
						<div class="num-wrap-ti">实际数量</div>
						<div class="num-wrap-con">{{goods.mergeRecordCount}}件</div>
					</div>
					<div class="ye-wrap flex-shrink flex-row align-center" v-if="goods.income !=null">
						<div class="ye-wrap-ti">余额</div>
						<div class="ye-wrap-con">{{goods.income}}元</div>
					</div>
					<div class="ye-wrap flex-row align-center flex-shrink"
						v-if="goods.tkOperationsWithdrawAmount !=null && goods.tkOperationsWithdrawAmount>0">
						<div class="ye-wrap-ti">余额</div>
						<div class="ye-wrap-con">{{goods.tkOperationsWithdrawAmount }}元</div>
					</div>
					<div class="points-wrap flex-shrink flex-row align-center" v-if="goods.havePoints!=null">
						<div class="points-wrap-ti">积分</div>
						<div class="points-wrap-con">{{goods.havePoints}}</div>
					</div>
					<div class="upoints-wrap flex-shrink flex-row align-center" v-if="goods.surplusPoints!=null">
						<div class="upoints-wrap-ti">未发放</div>
						<div class="upoints-wrap-con">{{goods.surplusPoints}}</div>
					</div> -->
				</div>
				<div class="flex-row align-center detail-item" v-if="goods.isOpen == '否'">
					<div class="detail-item-title">版号</div>
					<div class="detail-item-con">{{goods.periodView}}</div>
				</div>
				<div class="flex-row align-center detail-item">
					<div class="detail-item-title">发行方</div>
					<div class="detail-item-con">{{goods.issuerName}}</div>
				</div>
				<div class="flex-row align-center detail-item" v-if="goods.tokenId !='888888'">
					<div class="detail-item-title">权益</div>
					<div class="detail-item-con">{{goods.rightsDesc}}</div>
				</div>
			</div>
			<div class="good-detail-2">
				<div class="deal-info">交易信息</div>
				<div class="flex-row align-center detail-item">
					<div class="detail-item-title">交易模式</div>
					<div class="detail-item-con">C2C个人交易</div>
				</div>
				<img src="../assets/buy-notice.jpg" class="buy-notice" alt="">
			</div>
			<div class="recommend-title">历史公告</div>
			<div class="bg-grey">
				<van-list v-model="loading" @load="getPushMessageList" :finished="finished2" finished-text="没有更多了"
					:error.sync="error" error-text="请求失败，点击重新加载">
					<div class="news-list">
						<div v-for="(item,index) in  message" v-bind:key="index" class=" flex-row align-center item"
							@click.stop="navDetail2(index)">
							<div class="item-img-sec">
								<img :src="item.pic" class="item-img" />
							</div>
							<div class="flex-column justify-center">
								<div class="item-title">{{item.title}}</div>
								<div class="item-sen-title">{{item.subTitle}}</div>
							</div>
						</div>
					</div>
				</van-list>
			</div>
			<div class="recommend-title">相关推荐</div>
			<div class="good-list flex-row align-center flex-wrap space-between">
				<div class="good-item flex-column" v-for="(item,index) in list" v-bind:key="index"
					@click="navDetail(index,item.id,item.currentMinPrice,item.productId)">
					<div class="relative">
						<div v-if="item.isOpen == '是' &&  item.consignmentType ==21 && item.period !=8"
							class="goods-item-wrap" :style="'background-image: url('+item.pic+');'">
						</div>
						<img v-else-if="item.isOpen == '是' &&  item.consignmentType == 2 && item.period == 8"
							src="https://ipfs.allnfts.cn/ipfs/QmY95PAi4TuXWeh2vdt3XcpSrn4M8LQDeeixULxLsmg6Fa"
							class="good-item-img3" />
						<img v-else-if="item.isOpen == '是' &&  item.consignmentType == 1" :src="item.pic"
							class="good-item-img3" />
						<img v-else src="../assets/manghe.png" class="good-item-img2" />
						<div class="goods-nums2">x {{item.quantity}}</div>
						<img src="../assets/icon-ts2.png" v-if="item.quantity<=0" class="icon-ts" alt="">
					</div>
					<div class="good-item-con">
						<div class="good-item-name">{{item.isOpen=='是'?item.name:'盲盒'}}</div>
						<div class="good-item-price" v-if="item.currentMinPrice">￥{{item.currentMinPrice}}</div>
					</div>
				</div>
			</div>
			<div class="flex-row align-center space-between good-price-sec">
				<div class="flex-row align-center">
					<div class="good-price-title">最低价格：</div>
					<div class="good-price" v-if="minPrice">￥{{minPrice}}</div>
				</div>
				<!-- 		<div class="good-by-sec" @click.stop="buy()" v-if="goods.consignmentType == 2">
					<img src="../assets/good_buy_bg.png" class="good-by-bg" />
					<div class="buy-btn">卖家出售列表</div>
				</div> -->

				<div class="btn-wrap flex-row align-center">
					<div class="btn-left" @click.stop="sale()">我要卖</div>
					<div class="btn-right" @click.stop="buy()">我要买</div>
				</div>


			</div>
		</template>
		<template v-if="step ==2">
			<iframe id="myiframe" name="myiframe" :src="goods.link3D" width="" height=""></iframe>
		</template>

		<div class="model" v-if="confirmModal" @click.stop="closeBuyModal"></div>
		<div class="buy-confirm-wrap" v-if="confirmModal">
			<div class="bc-top flex-row align-center">
				<div class="bc-ti">选择购买数量</div>
				<div class="close-bc" @click.stop="closeBuyModal">关闭</div>
			</div>
			<div class="flex-row align-center tips-wrap">
				<img src="../assets/icon-tips.png" class="icon-tips" alt="">
				<div class="tips">批量购买需要消耗88积分</div>
			</div>
			<div class="num-con flex-row align-center justify-center">
				<div class="add" @click.stop="reduce()">-</div>
				<input type="text" onkeyup="value=value.replace(/[^\d]/g,'')"
					oninput="if(value.length > 2)value = value.slice(0,2)" class="num" v-model="num">
				<div class="reduce" @click.stop="add()">+</div>
			</div>

			<div class="confirm-btn" @click.stop="confirmBuy()">确认</div>
		</div>


	</div>
</template>

<script>
	import LoginWrap from '@/components/Login.vue';
	import util from '../config/util.js';
	import config from '../config/config.js';
	import wx from 'weixin-js-sdk';
	import QRCode from 'qrcodejs2';
	import {
		Toast,
		ImagePreview,
		Dialog
	} from 'vant';
	import 'vant/lib/toast/index.css'
	import 'vant/lib/dialog/index.css'
	import 'vant/lib/index.css'

	export default {
		data() {
			return {
				id: '',
				goods: {},
				list: [],
				showLogin: 0,
				qrcodeImg: '',
				showQrcode: 0,
				finished: 0,
				step: 1,
				showImg: 0,
				videoModal: 0,
				tkData: {},
				pid: 0,
				pageNum: 1,
				pageSize: 10,
				loading: false,
				finished2: false,
				error: false,
				message: [],
				minPrice: 0,
				confirmModal: false,
				num: 5
			}
		},
		components: {
			LoginWrap,
		},
		methods: {
			sale() {
        if (!this.$store.state.user.token) {
          this.login();
        } else {
          this.$router.push({
            path: '/collect',
            query: {
              name:this.goods.name
            }
          })
        }

			},
			confirmBuy() {
				const that = this;
				let params = new FormData()
				params.append('buyQuantity', that.num)
				params.append('consignmentId', that.id)
				this.axios.post(config.requestUrl + '/front/order/batchCreateOrderByConsignment', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.$router.push({
							path: '/pay',
							query: {
								type: 2,
								order_id: response.data.obj.id,
								name: that.goods.name,
								productPic: that.goods.isOpen == '是' ? that.goods.pic :
									'../assets/manghe.png',
								payAmount: response.data.obj.payAmount,
								salePrice: response.data.obj.payAmount,
								isOpen: that.goods.isOpen
							}
						})
					} else {
						Toast(response.data.msg);
					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})


				// let params = new FormData()
				// params.append('orderId', 58750)
				// this.axios.post(config.requestUrl + '/front/order/queryOrderStatus', params, {
				// 	headers: {
				// 		'Content-Type': 'multipart/form-data',
				// 		'token': this.$store.state.user.token
				// 	}
				// }).then(response => {
				// 	if (response.data.code == 0) {
				// 		console.log(response)
				// 	} else {
				// 		Toast(response.data.msg);
				// 	}
				// }, response => {
				// 	Toast('获取失败，请稍后重试');
				// })

			},
			closeBuyModal() {
				this.confirmModal = 0
			},
			add() {
				if (Math.abs(parseInt(this.num)) < 10) this.num = Math.abs(parseInt(this.num)) + 1
			},
			reduce() {
				if (Math.abs(parseInt(this.num)) > 1) this.num = Math.abs(parseInt(this.num)) - 1
			},
			batchBuy() {
				if (!this.$store.state.user.token) {
					this.login();
				} else {
					this.confirmModal = true;
				}
			},
			navDetail2(index) {
				this.$router.push('/newsDetail?id=' + this.message[index].id)
			},
			addShop() {
				const that = this;
				Dialog.confirm({
					title: "提示",
					message: '是否确定添加到古玩店？'
				}).then(() => {
					let params = new FormData()
					params.append('consignmentId', that.id)
					params.append('shopId', -1)
					that.axios.post(config.requestUrl + '/front/antiqueShop/joinMyAntiqueShop', params, {
						headers: {
							'Content-Type': 'multipart/form-data',
							'token': that.$store.state.user.token
						}
					}).then(response => {
						if (response.data.code == 0) {
							that.goods.canAddToShopFlag = false;
							Toast.success('添加成功')
						} else {
							Toast(response.data.msg);
						}
					}, response => {
						Toast('获取失败，请稍后重试');
					})
				})
			},
			getOperationsInfoByEntryId(productEntryId) {
				const that = this;
				let params = new FormData()
				params.append('productEntryId', productEntryId)
				this.axios.post(config.requestUrl + '/front/agencyOperations/getOperationsInfoByEntryId', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						if (response.data.obj) {
							that.tkData = response.data.obj;
						}
					} else {
						Toast(response.data.msg);
					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			},
			navTk() {
				this.$router.push('/tkdata?productEntryId=' + this.pid + '&price=' + this.goods.tkOperationsWithdrawAmount)
			},
			cancelShop() {
				const that = this;
				Dialog.confirm({
					title: "提示",
					message: '是否确定从古玩店中撤销？'
				}).then(() => {
					let params = new FormData()
					params.append('consignmentId', that.id)
					params.append('shopId', -1)
					that.axios.post(config.requestUrl + '/front/antiqueShop/cancelMyAntiqueShop', params, {
						headers: {
							'Content-Type': 'multipart/form-data',
							'token': that.$store.state.user.token
						}
					}).then(response => {
						if (response.data.code == 0) {
							that.goods.canAddToShopFlag = true;
							Toast.success('撤销成功')
						} else {
							Toast(response.data.msg);
						}
					}, response => {
						Toast('获取失败，请稍后重试');
					})
				})
			},
			login() {
				this.showLogin = true;
			},
			closeLogin() {
				this.showLogin = false;
			},
			nav3D() {
				const that = this;
				console.log(this.goods.link3D)
				if (this.goods.link3D != '' && this.goods.link3D != null) {
					if (this.goods.isOpen == '是') {
						document.title = this.goods.name;
					} else {
						document.title = '盲盒';
					}
					if (this.goods.link3D.indexOf('.jpg') != '-1') {
						this.showImg = 1;
						ImagePreview({
							images: [
								this.goods.link3D
							],
							closeable: true,
							showIndex: false,
							overlayStyle: {
								background: '#000000'
							},
							onClose() {
								that.showImg = 0;
							},
						})
					} else if (this.goods.link3D.indexOf('.mp4') != '-1') {
						this.videoModal = 1;
						let myVideo = document.getElementById('myVideo');
						myVideo.play();
						myVideo.addEventListener('pause', function() { //暂停
							console.log("暂停");
							that.videoModal = 0;
						});
						myVideo.addEventListener('ended', function() { //结束
							that.videoModal = 0;
						}, false);
					} else if (this.goods.link3D == 'https://shop.metacbc.cn/models') {
						this.$router.push('/models')
					} else if (this.goods.link3D == 'https://shop.metacbc.cn/models2') {
						this.$router.push('/models2')
					} else {
						this.step = 2;
						this.$forceUpdate()
					}
				}
			},
			async goodsDetail() {
				const that = this
				let params = new FormData()
				params.append('consignmentId', that.id)
				params.append('productId', this.pid ? this.pid : 0)
				that.axios.post(config.requestUrl + '/front/consignment/newGetConsignmentDetail', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': that.$store.state.user.token ? that.$store.state.user.token : ''
					}
				}).then(response => {
					that.request = 0;
					if (response.data.code == 0) {
						that.goods = response.data.obj;
						let iconType = 0;
						if (that.goods.link3D != '' && that.goods.link3D != null) {
							if (that.goods.link3D.indexOf('.jpg') != '-1') {
								iconType = 1;
							} else if (that.goods.link3D.indexOf('.mp4') != '-1') {
								iconType = 2;
							} else {
								iconType = 3;
							}
						}
						that.goods.iconType = iconType;
						// let productId = response.data.obj.productEntryIdMap.replace(/{/, "")

						// productId = productId.replace(/}/, "")
						// productId = productId.split(':');
						// console.log(productId)
						// if (this.goods.isOpen == '否' || productId.length > 2) {
						// 	that.showQrcode = 0;
						// } else {
						// 	productId = productId[1];
						// 	let qrcode = new QRCode(that.$refs.qrCodeDiv, {
						// 		text: config.qrcodeHost + 'certify?productId=' + productId,
						// 		width: 200,
						// 		height: 200,
						// 		colorDark: "#000000", //二维码颜色
						// 		colorLight: "#00FA9A", //二维码背景色
						// 		correctLevel: QRCode.CorrectLevel.L //容错率，L/M/H
						// 	})
						// 	let canvas = qrcode._el.querySelector(
						// 		"canvas"); //获取生成二维码中的canvas，并将canvas转换成base64
						// 	that.qrcodeImg = canvas.toDataURL("image/png");
						// 	that.showQrcode = 1;
						// 	that.getOperationsInfoByEntryId(productId)
						// 	that.pid = productId
						// }
						that.finished = 1;
						// util.detailWxConfig(that.id, that.goods.name, that.goods.pic, that.goods.isOpen, that
						// 	.goods.nickName);
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							that.$store.commit("clearUser");
							that.$store.commit("clearCode");
							this.showLogin = 1;
						}
						Toast(response.data.msg);
					}
				}, response => {
					that.request = 0;
					Toast('获取失败，请稍后重试');
				})
			},
			getPushMessageList() {
				const that = this
				let params = new FormData()
				params.append('pageNum', that.pageNum);
				params.append('pageSize', that.pageSize);
				params.append('consignmentId', that.id)
				if (that.pid > 0) {
					params.append('productId', that.pid)
				}
				that.axios.post(config.requestUrl + '/search/getPushMessageList', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': that.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						let newList = response.data.obj.list;
						let list = that.message;
						let finished = false;
						if (response.data.obj.isLastPage || !response.data.obj.hasNextPage) finished =
							true;
						list = list.concat(newList);
						that.message = list;
						that.finished2 = finished;
						that.pageNum = that.pageNum + 1;
						that.error = false;
					} else {
						let msg = response.data.msg;
						if (msg == '') msg = '获取失败，请稍后重试'

						Toast(msg);
						that.error = true;
					}
					that.loading = false;
				}, response => {
					that.loading = false;
					that.error = true;
					Toast('获取失败，请稍后重试');
				})
			},
			queryRelatedRecommendList() {
				const that = this
				let params = new FormData()
				params.append('consignmentId', that.id)
				params.append('productId', this.pid ? that.pid : 0)
				that.axios.post(config.requestUrl + '/front/consignment/queryRelatedRecommendList', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': that.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						let data = response.data.obj;
						for (let i in data) {
							data[i].isAll = 0;
							let productId = data[i].productEntryIdMap;
							if (productId.length > 0) {
								productId = productId.replace(/{/, "")
								productId = productId.replace(/}/, "")
								productId = productId.split(':');
								if (productId.length > 2) {
									data[i].isAll = 1;
								}
							}
						}
						that.list = data
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							that.$store.commit("clearUser");
							that.$store.commit("clearCode");
							this.showLogin = 1;
						}
						Toast(response.data.msg);
					}
				}, response => {
					that.request = 0;
					Toast('获取失败，请稍后重试');
				})
			},
			buy() {
				if (!this.$store.state.user.token) {
					this.login();
				} else {
					if (this.finished) {
						// let name = '盲盒';
						// let period = '';
						// if (this.goods.isOpen == '是') {
						// 	let isAll = 0;
						// 	let productId = this.goods.productEntryIdMap;
						// 	if (productId.length > 0) {
						// 		productId = productId.replace(/{/, "")
						// 		productId = productId.replace(/}/, "")
						// 		productId = productId.split(':');
						// 		if (productId.length > 2) {
						// 			isAll = 1;
						// 		}
						// 	}
						// 	if (isAll) {
						// 		name = this.goods.name + this.goods.periodView
						// 	} else {
						// 		name = this.goods.name
						// 	}
						// } else {
						// 	name = this.goods.periodView
						// }

						// this.$router.push({
						// 	path: '/pay',
						// 	query: {
						// 		id: this.id,
						// 		name: name,
						// 		productPic: this.goods.isOpen == '是' ? this.goods.pic : '../assets/manghe.png',
						// 		productEntryNo: this.goods.isOpen == '是' ? this.goods.productEntryNo : period,
						// 		salePrice: this.goods.salePrice,
						// 		isOpen: this.goods.isOpen
						// 	}
						// })
						this.$router.push({
							path: '/proList',
							query: {
								name: this.goods.name,
								isOpen: this.goods.isOpen,
								consignmentType: this.goods.consignmentType,
								period: this.goods.period,
								productPic: this.goods.pic,
								consignmentId:this.id
							}
						})
					}
				}
			},
			navDetail(index, id, price, productId) {
				this.$router.push(`/detail?id=${id}&price=${price}&productId=${productId}`)
				// this.$router.push({
				// 	path: '/detail',
				// 	query: {
				// 		id
				// 	}
				// })
			},
			closeVideo() {
				this.videoModal = 0
			}
		},
		activated() {
			this.id = this.$route.query.id;
			this.minPrice = this.$route.query.price
			this.pid = this.$route.query.productId;
			this.showQrcode = 0;
			this.step = 1;
			this.finished = 0;
			this.confirmModal = 0;
			this.num = 5;
			this.goodsDetail();
			this.queryRelatedRecommendList();
			this.videoModal = 0
			document.body.scrollIntoView()
			// ImagePreview(['https://oss.metacbc.cn/img/%E5%8F%AA%E6%AD%A4%E9%9D%92%E7%BB%BF%E5%9C%A8%E4%BA%BA%E9%97%B4.jpg'])

		},
		beforeRouteLeave(to, from, next) {
			if (to.path == '/' || to.name == 'home') {
				if (this.showLogin) {
					this.showLogin = false
					next(false)
				} else if (this.step == 2) {
					this.step = 1;
					console.log(`this.step${this.step}`)
					next(false);
				} else if (this.showImg == 1) {
					console.log('this.showImg' + this.showImg)
					this.showImg = 0;
					next(false);
				} else {
					next();
				}
			} else {
				this.showLogin = false;
				next();
			}
		},
		mounted() {
			// util.wxConfig();
		}
	}
</script>

<style scoped="scoped">
	.container {
		width: 100%;
		height: 100%;
		background: rgba(203, 203, 203, 0.1);
		min-height: 100%;
		padding-bottom: 88px;
		padding-top: 0 !important;
	}

	.for-img {
		position: relative;
	}

	.good-img {
		width: 750px;
		height: 841px;
	}

	.icon-play {
		width: 100px;
		height: 100px;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		margin: auto;
	}

	.icon-3d {
		width: 100px;
		height: 100px;
		right: 36px;
		bottom: 36px;
		position: absolute;

	}

	.goods-img-wrap {
		width: 750px;
		height: 1000px;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center -100px;
	}

	.good-name-sec {
		width: 750px;
		height: 120px;
		background: rgba(203, 203, 203, 0.1);
		position: relative;
	}

	.good-name-bg {
		width: 750px;
		height: 120px;
	}

	.good-name-con {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.good-name {
		font-size: 32px;
		font-weight: 600;
		color: #FFFFFF;
		line-height: 32px;
		margin-left: 40px;
	}

	.good-status-sec {
		width: 248px;
		height: 120px;
		position: relative;
	}

	.good-status-bg {
		width: 248px;
		height: 120px;
	}

	.good-status-con {
		position: absolute;
		width: 226px;
		height: 120px;
		left: 22px;
		top: 0;
	}

	.sale-icon {
		width: 132px;
		height: 40px;
		margin-top: 18px;
	}

	.good-status {
		font-size: 34px;
		font-family: Helvetica;
		color: #FFFFFF;
		line-height: 34px;
		margin-top: 8px;
	}

	.good-detail-1 {
		width: 750px;
		background: #FFFFFF;
		padding: 0 0 40px 40px;
		box-sizing: border-box;
	}

	.detail-item {
		padding-top: 30px;
	}

	.detail-item-title {
		flex-shrink: 0;
		font-size: 26px;
		color: rgba(0, 0, 0, 0.5);
		line-height: 26px;
		width: 105px;
	}

	.detail-item-con {
		font-size: 26px;
		color: #000000;
		line-height: 26px;
		margin-left: 46px;
		word-break: break-all;
		word-wrap: break-word;
	}

	.good-detail-2 {
		width: 750px;
		background: #FFFFFF;
		/* padding: 30px 0 40px 40px; */
		padding: 30px 0 0 40px;
		box-sizing: border-box;
		margin-top: 10px;
	}

	.deal-info {
		font-size: 26px;
		color: #000000;
		line-height: 30px;
	}

	/* 推荐部分 */
	.recommend-title {
		width: 750px;
		height: 90px;
		background: #F4F4F4;
		font-size: 30px;
		font-weight: 600;
		color: #000000;
		line-height: 90px;
		padding-left: 40px;
		box-sizing: border-box;
		margin-top: 52px;
	}

	/* 底部购买部分 */
	.good-price-sec {
		position: fixed;
		left: 0;
		bottom: 0;
		width: 750px;
		height: 98px;
		background: #FFFFFF;
		box-shadow: 0px -8px 19px 0px rgba(0, 0, 0, 0.07);
	}

	.good-price-title {
		font-size: 30px;
		color: rgba(0, 0, 0, 0.5);
		line-height: 30px;
		margin-left: 40px;
	}

	.good-price {
		font-size: 30px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #FF0000;
		line-height: 30px;
	}

	.good-by-sec {
		width: 302px;
		height: 98px;
		position: relative;
	}

	.good-by-bg {
		width: 302px;
		height: 98px;
	}

	.buy-btn {
		position: absolute;
		left: 0;
		top: 0;
		width: 302px;
		height: 98px;
		font-weight: 600;
		color: #FFFFFF;
		line-height: 98px;
		margin-left: 116px;
	}

	.good-img2 {
		width: 750px;
		height: 750px;
		padding: 45px 0;
	}

	/*下面码*/
	.xian-img {
		width: 750px;
		height: 8px;
		vertical-align: bottom;
	}

	.qrcode-wrap {
		width: 750px;
		height: 292px;
		background: #000000;
	}

	.qrcode-wrap-left {
		width: 350px;
		margin-left: 40px;
	}

	.qrcode-goods-name {
		font-size: 28px;
		font-weight: bold;
		color: #FFFFFF;
		word-break: break-all;
		word-wrap: break-word;
	}

	.qrcode-goods-fx {
		margin-top: 25px;
		font-size: 19px;
		font-weight: bold;
		color: #FFFFFF;
		word-break: break-all;
		word-wrap: break-word;
	}

	.qrcode-goods-bh {
		margin-top: 23px;
		font-size: 19px;
		font-weight: bold;
		color: #FFFFFF;
		word-break: break-all;
		word-wrap: break-word;
	}

	.qrcode-goods-tk {
		margin-top: 14px;
		font-size: 19px;
		font-weight: bold;
		color: #FFFFFF;
		word-break: break-all;
		word-wrap: break-word;
	}

	.qrcode-wrap-right {
		width: 158px;
		margin-right: 48px;
	}

	.qrcode-img {
		width: 156px;
		height: 156px;
	}

	.qrcode-img img {
		width: 156px;
		height: 156px;
	}

	.qrcode-img image {
		width: 156px;
		height: 156px;
	}

	.qrcode-desc {
		margin-top: 20px;
		font-size: 25px;
		font-weight: bold;
		color: #FFFFFF;
	}

	iframe {
		width: 100%;
		height: 100%;
		border: none;
	}

	iframe>* {
		max-width: 100%;
	}

	.num-wrap {
		margin-top: 37px;
		margin-right: 36px;
	}

	.num-wrap2 {
		margin-top: 10px;
		margin-right: 15px;
	}

	.num-wrap2:first-child {
		margin-top: 0;
	}

	.num-wrap-ti {
		padding: 10px 14px;
		border-radius: 6px;
		background: linear-gradient(#3FBDFF, #1E5EFF);
		font-size: 24px;
		font-weight: 400;
		color: #FFFFFF;
	}

	.num-wrap-con {
		padding: 8px;
		background: #E3EDFF;
		border-radius: 4px;
		font-size: 24px;
		font-weight: 400;
		color: #2369CC;
	}

	.ye-wrap {
		margin-top: 37px;
		margin-right: 36px;
	}

	.ye-wrap-ti {
		padding: 10px 14px;
		border-radius: 6px;
		background: linear-gradient(#AA8AF7, #5850EC);
		font-size: 24px;
		font-weight: 400;
		color: #FFFFFF;
	}

	.ye-wrap-con {
		padding: 8px;
		background: rgba(98, 54, 255, 0.14);
		border-radius: 4px;
		font-size: 24px;
		font-weight: 400;
		color: #523094;
	}

	.video-model {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		width: 750px;
		height: max-content;
		z-index: 1001;
	}

	video {
		position: absolute;
		z-index: 10;
		left: 0;
		top: 0;
		width: 750px;
		height: 841px;
		background: rgba(0, 0, 0, 1);
	}

	.add-icon {
		margin-top: 18px;
		width: 93px;
		height: 40px;
	}

	.add-text {
		font-size: 26px;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 34px;
		margin-top: 8px;
	}

	.points-wrap {
		margin-top: 37px;
		margin-right: 36px;
	}

	.points-wrap-ti {
		padding: 10px 14px;
		border-radius: 6px;
		background: linear-gradient(180deg, #FFB684 0%, #FF7311 100%);
		font-size: 24px;
		font-weight: 400;
		color: #FFFFFF;
	}

	.points-wrap-con {
		padding: 8px;
		background: #FFF0DD;
		border-radius: 4px;
		font-size: 24px;
		font-weight: 400;
		color: #FA6400;
	}

	.upoints-wrap {
		margin-top: 37px;
		margin-right: 36px;
	}

	.upoints-wrap-ti {
		padding: 10px 14px;
		border-radius: 6px;
		background: linear-gradient(180deg, #CECECE 0%, #8E8E8E 100%);
		font-size: 24px;
		font-weight: 400;
		color: #FFFFFF;
	}

	.upoints-wrap-con {
		padding: 8px;
		background: #E7E7E7;
		border-radius: 4px;
		font-size: 24px;
		font-weight: 400;
		color: #767676;
	}

	.buy-notice {
		width: 680px;
		height: 410px;
		margin-top: 60px;
	}


	.news-list {
		padding: 20px 20px 0 20px;
		background: #F4F4F4;
	}

	.bg-grey {
		background: #F4F4F4;
	}

	.item {
		width: 710px;
		height: 120px;
		background: #FFFFFF;
		border-radius: 10px;
		margin-top: 20px;
		padding: 0 27px;
		box-sizing: border-box;
	}

	.item-img-sec {
		position: relative;
		margin-right: 37px;
	}

	.item-dot {
		position: absolute;
		z-index: 10;
		top: -3px;
		right: -3px;
		width: 16px;
		height: 16px;
		background: #FF0000;
		border-radius: 50%;
	}

	.item-img {
		width: 86px;
		height: 86px;
		border-radius: 10px;
	}

	.item-title {
		font-size: 28px;
		font-weight: bold;
		color: #000000;
		line-height: 30px;
	}

	.item-sen-title {
		font-size: 24px;
		color: rgba(0, 0, 0, 0.5);
		line-height: 24px;
		margin-top: 10px;
	}


	.btn-wrap {
		width: max-content;
		height: 98px;
		background: linear-gradient(138deg, #9764ff 0%, #6539ff 100%);
		border-radius: 49px 0 0 49px;
		padding: 34px 0;
		box-sizing: border-box;
		/* margin-right: 30px; */

	}

	.btn-left {
		width: 200px;
		border-right: 3px solid #f2901c;
		text-align: center;
		box-sizing: border-box;
		font-size: 30px;
		font-weight: 600;
		color: #FFFFFF;
	}

	.btn-right {
		width: 180px;
		text-align: center;
		font-size: 30px;
		font-weight: 600;
		color: #FFFFFF;
	}

	.buy-confirm-wrap {
		position: fixed;
		left: 0;
		bottom: 0;
		z-index: 1001;
		background: #FFFFFF;
		padding-bottom: 18px;
	}

	.bc-top {
		width: 750px;
		height: 88px;
		background: #FFFFFF;
		border-radius: 20px 20px 0px 0px;
		position: relative;
	}

	.bc-ti {
		width: 750px;
		text-align: center;
	}

	.close-bc {
		position: absolute;
		top: 30px;
		right: 34px;
		font-size: 28px;
		font-weight: 400;
		color: #000000;
	}

	.tips-wrap {
		width: 750px;
		background: rgba(247, 181, 0, 0.1);
		border-radius: 0px 0px 20px 20px;
		padding: 20px 40px;
		box-sizing: border-box;
	}

	.icon-tips {
		width: 21px;
		height: 24px;
		margin-right: 9px;
	}

	.tips {
		font-size: 24px;
		font-weight: 600;
		color: #FA6400;
	}

	.addr-wrap {
		width: 388px;
		height: 80px;
		margin: 60px auto 89px;
		background: rgba(201, 201, 201, 0.1);
		border-radius: 10px;
		border: 1px solid rgba(0, 0, 0, 0.1);
	}

	.addr-input {
		width: 318px;
		height: 80px;
		background: rgba(201, 201, 201, 0.1);
		border: none;
		font-size: 28px;
		font-weight: 400;
		padding: 0 18px;
		box-sizing: border-box;
	}

	.ques-img {
		width: 32px;
		height: 32px;
		margin-left: 21px;
	}

	.num-con {
		margin: 60px auto 0
	}

	.reduce {
		width: 130px;
		height: 80px;
		background: rgba(255, 255, 255, 0.1);
		border-radius: 10px 0px 0px 10px;
		border: 1px solid rgba(0, 0, 0, 0.1);
		line-height: 80px;
		text-align: center;
		color: #000000;
		font-size: 35px;
		font-weight: bolder;
	}

	.num {
		width: 130px;
		height: 80px;
		background: rgba(0, 0, 0, 0.1);
		text-align: center;
		border: none;
	}

	.add {
		width: 130px;
		height: 80px;
		background: rgba(255, 255, 255, 0.1);
		border-radius: 10px 0px 0px 10px;
		border: 1px solid rgba(0, 0, 0, 0.1);
		line-height: 80px;
		text-align: center;
		color: #000000;
		font-size: 35px;
		font-weight: bolder;
	}

	.confirm-btn {
		width: 590px;
		height: 80px;
		margin: auto;
		background: linear-gradient(138deg, #9764ff 0%, #6539ff 100%);
		border-radius: 40px;
		text-align: center;
		font-size: 32px;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 80px;
		margin-top: 80px
	}
</style>